import React from "react";

function NotFound() {
  return (
    <div>
      <h1>Error: Page is not found</h1>
    </div>
  );
}

export default NotFound;